import { lazy } from 'react';
import { Button } from "react-bootstrap";
import { error_image } from "assets";
import { useMediaQuery } from "react-responsive";

const SiteNavbar = lazy(() => import("components/Navbar/Navbar"));

const Error = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <>
      <div className="w-100 d-flex">
        <SiteNavbar className="position-absolute top-0 w-100" />
      </div>
      <div
        className="w-100 h-100 scrollbar-none bg-whitish d-flex flex-column justify-content-center align-items-center"
        style={{ overflow: "scroll" }}
      >
        <div className="text-center">
          <img
            style={{ height: large ? 500 : 180 }}
            src={error_image}
            alt="Error"
          />
        </div>
        <div
          className="mt-3 text-center"
          style={{ fontWeight: 500, fontSize: "24px", lineHeight: "36px" }}
        >
          Oops! Page Not Found - 404 Error.
        </div>
        <Button
          variant="primary"
          type="submit"
          className=" mt-4 focus-none d-flex justify-content-center align-items-center px-4 py-2"
          style={{
            minWidth: 190,
            height: large ? 56 : medium ? 50 : 44,
            borderRadius: 8,
          }}
          onClick={() => (window.location.href = "/")}
        >
          <p className="text-white"> BACK TO HOME</p>
        </Button>
      </div>
    </>
  );
};

export default Error;
