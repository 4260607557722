import { lazy } from 'react';

const Footer = lazy(() => import("components/Footer/SiteFooter"));
const SiteNavbar = lazy(() => import("components/Navbar/Navbar"));
const React = lazy(() => import("react"));

const TermsOfService = () => {
  return (
    <div>
      <div>
        <SiteNavbar />
      </div>
      <div className="vh-100">Terms of Service</div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default TermsOfService;
