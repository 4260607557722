import { useCallback, useEffect, useState, lazy } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import { BiChevronsRight } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";

import {
  Default_blog_image,
  linked_in_black,
  whatsapp_black,
  facebook_black,
  twitter_black,
} from "assets";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const SiteNavbar = lazy(() => import("components/Navbar/Navbar"));
const Footer = lazy(() => import("components/Footer/SiteFooter"));
const BlogCard = lazy(() => import("components/Blogs/BlogCard"));


// const blogsData = [
//   {
//     title: "The World Is Falling Short on Emissions Goals — What's Being Done?",
//     date_published: "Mar 27, 2023",
//     content:
//       "The Earth has been on a consistent warming trend since the 19th century. During this time, the surface temperature has risen roughly 2 degrees Fahrenheit. This has resulted in rising sea levels, which has displaced people and wildlife in low-lying areas, and severe weather patterns that have caused droughts, flooding, severe storms, and even wildfires.",
//     img: blog1,
//   },
// ];

const ShareMenu = () => {
  const share_url = window.location.href;
  console.log(share_url);

  return (
    <div className="d-flex flex-column align-items-center text-lg-center mb-4">
      <p className="mb-3 text-secondary">Share on</p>
      <div className="d-flex">
        <WhatsappShareButton url={share_url} className="ms-0 me-4 my-3">
          {/* <IoLogoWhatsapp size={28} className="me-3" /> */}
          <Image src={whatsapp_black} alt="whatsapp" loading="lazy"/>
        </WhatsappShareButton>

        <LinkedinShareButton url={share_url} className="ms-0 me-4 my-3">
          {/* <ImLinkedin2 size={28} className="me-3" /> */}
          <Image src={linked_in_black} alt="linkedin" loading="lazy"/>
        </LinkedinShareButton>

        <FacebookShareButton
          url={share_url}
          className="ms-0 me-4 my-3"
          quote="facebook"
        >
          {/* <ImFacebook size={28} className="me-3" /> */}
          <Image src={facebook_black} alt="facebook" loading="lazy"/>
        </FacebookShareButton>

        <TwitterShareButton url={share_url} className="ms-0 me-4 my-3">
          {/* <ImTwitter size={28} className="" /> */}
          <Image src={twitter_black} alt="twitter" loading="lazy"/>
        </TwitterShareButton>
      </div>
    </div>
  );
};

const Blog = () => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const { slug } = useParams();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  // const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";

  const [blogData, setBlogData] = useState(null);
  const [blogContent, setBlogContent] = useState("");
  const [blogsData, setBlogsData] = useState([]);
  const [error, setError] = useState("");

  const fetchBlog = async (slug) => {
    const userActions = await import("actions/UserActions");
    const response = await userActions.fetchBlog(slug);
    if (response) {
      // console.log(response);
      setBlogData(response);
    } else {
      setError("Blog not found !");
    }
  };

  const fetchBlogs = useCallback(async () => {
    const userActions = await import("actions/UserActions");
    const response = await userActions.fetchBlogs(`/blogs/?ordering=LATEST`);
    console.log(response);
    if (response) {
      setBlogsData(response.results.filter((result) => result.slug !== param2));
    }
  }, [param2]);

  useEffect(() => {
    let x = blogData?.content;
    let divElement = document.createElement("div");
    divElement.innerHTML = x;

    // looping through ALL DOM elements inside the divElement
    let elements = divElement.getElementsByTagName("*");
    for (let i = 0; i < elements.length; i++) {
      // remove the style attribute enterily if it contains font-size property
      // console.log("ELEM", elements[i].getAttribute("style"));
      if ((elements[i].getAttribute("style") || "").includes("font-family")) {
        elements[i].removeAttribute("style");
      }
    }
    // console.log("divElement.innerHTML", divElement.innerHTML);
    setBlogContent(divElement.innerHTML);
  }, [blogData]);

  useEffect(() => {
    if (slug) {
      // console.log(slug);
      fetchBlog(slug);
    }
    fetchBlogs();
  }, [slug, fetchBlogs]);

  return (
    <div>
      <div>
        <SiteNavbar />
      </div>
      {blogData ? (
        <div className="pt-md-4 pt-lg-5 my-5">
          <section className="mb-4">
            <div className="blog-header-wrapper py-4 mb-lg-5">
              <div className="px-3 py-3 mb-3">
                <h4 className="user-select-none">
                  <a
                    className="cursor-pointer"
                    href="/blogs"
                  // onClick={() => navigate("/blogs")}
                  >
                    Blogs
                  </a>
                  <BiChevronsRight size={24} className="mx-1 text-grey" />
                  <span className="text-grey">{blogData?.title}</span>
                </h4>
              </div>
              <div className={large ? "bg-white" : "bg-lightgreen"}>
                <div className={`text-center`}>
                  <div
                    className={large ? "bg-lightgreen p-3" : "py-4"}
                    style={{ borderRadius: large ? 16 : 0 }}
                  >
                    <h3 className="mb-3 mb-md-4">{blogData?.title}</h3>
                    {blogData?.author_name && (
                      <p className="mb-3 mb-md-3 text-secondary">
                        Published by {blogData?.author_name}
                      </p>
                    )}
                    {blogData?.published_date && (
                      <p className="mb-3 mb-md-4 text-secondary">
                        Published on {blogData?.published_date}
                      </p>
                    )}
                    <ShareMenu />
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper blog-wrapper">
              <div className="w-100">
                <img
                  src={
                    blogData?.blog_image
                      ? blogData.blog_image
                      : Default_blog_image
                  }
                  alt={blogData?.slug}
                  className="img-fluid w-100"
                  style={{ borderRadius: large ? 24 : 16 }}
                />
              </div>
              <div
                className="px-2 px-sm-0 my-4"
                dangerouslySetInnerHTML={{ __html: blogContent }}
              />
              <hr className="mx-auto" />
              {/* <hr className="mx-auto" style={{ width: "80%" }} /> */}
            </div>
            {blogData && (
              <div>
                <ShareMenu />
              </div>
            )}
          </section>
          {blogsData?.length > 0 && (
            <section className="wrapper">
              <div className="mb-4">
                <div className="mb-3">
                  <h3 className="font-w500">Read More Blogs</h3>
                </div>
                <Row xs={1} md={2} lg={3}>
                  {blogsData?.map((blog, index) => {
                    return (
                      index < 3 && (
                        <Col key={index} className="p-2">
                          <div
                          // className="border p-1"
                          // style={{ borderRadius: 16 }}
                          >
                            <BlogCard
                              key={index}
                              blog_id={blog?.id}
                              title={blog?.title}
                              date_published={blog?.published_date_published}
                              img={blog?.blog_image}
                              blog_slug={blog?.slug}
                              show_read={false}
                            />
                          </div>
                        </Col>
                      )
                    );
                  })}
                </Row>
              </div>
            </section>
          )}
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center py-4"
          style={{ minHeight: 300 }}
        >
          {error ? <p>{error}</p> : <Spinner animation="border" />}
        </div>
      )}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Blog;
