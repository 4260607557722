import Blog from "pages/Blogs/Blog";
import Blogs from "pages/Blogs/Blogs";
import GetInTouch from "pages/GetInTouch/GetInTouch";
import Home from "pages/Home/Home";
import Main from "pages/Main";
import TermsOfService from "pages/Policies/TermsOfService";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import Error from "pages/Error/Error";

const Routers = () => {
  const isUnderMaintenance = process.env.REACT_APP_MAINTENANCE;

  return isUnderMaintenance === "true" ? (
    <Router>
      <Routes>
        <Route path="" element={<Main />} />
      </Routes>
    </Router>
  ) : isUnderMaintenance === "false" ? (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="main" element={<Main />} />
        <Route path="blogs" element={<Outlet />}>
          <Route path="" element={<Blogs />} />
          <Route path=":slug" element={<Blog />} />
        </Route>
        <Route path="get-in-touch" element={<GetInTouch />} />
        <Route path="privacy-policy" element={<TermsOfService />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  ) : (
    <div className="d-flex justify-content-center vh-100">
      <h4 className="text-danger">Page Not Found</h4>
    </div>
  );
};

export default Routers;
