import { useEffect, useState, lazy } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { BiChevronsRight } from "react-icons/bi";

const Footer = lazy(() => import("components/Footer/SiteFooter"));
const SiteNavbar = lazy(() => import("components/Navbar/Navbar"));
const BlogCard = lazy(() => import("components/Blogs/BlogCard"));
const StayInTouch = lazy(() => import("components/Home/StayInTouch"));

const Blogs = () => {
  const [blogsData, setBlogsData] = useState([]);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState("LATEST");
  const [nextUrl, setNextUrl] = useState(null);

  const fetchBlogs = async (sortBy) => {
    try {
      const userActions = await import("actions/UserActions");
      let ordering = "";
      switch (sortBy) {
        case "LATEST":
          ordering = "-published_date";
          break;
        case "OLDEST":
          ordering = "published_date";
          break;
        default:
          break;
      }
      const response = await userActions.fetchBlogs(`/blogs/?ordering=${ordering}`);
      if (response) {
        setNextUrl(response.next);
        setBlogsData(response.results);
      } else {
        setError("Blogs not found or there is some issue in getting blogs!");
      }
    } catch (error) {
      setError("Error fetching blogs.");
      console.error("Failed to load user actions:", error);
    }
  };

  const fetchMoreBlogs = async () => {
    try {
      const userActions = await import("actions/UserActions");
      const response = await userActions.fetchBlogs(nextUrl);
      if (response) {
        setNextUrl(response.next);
        setBlogsData((prevBlogs) => [...prevBlogs, ...response.results]);
      } else {
        setError("Blogs not found or there is some issue in getting blogs!");
      }
    } catch (error) {
      setError("Error fetching more blogs.");
      console.error("Failed to load user actions:", error);
    }
  };

  const handleSearch = async (text) => {
    try {
      const userActions = await import("actions/UserActions");
      const response = await userActions.searchBlog(text);
      if (response) {
        setBlogsData(response.results);
        if (response.results.length === 0) {
          setError("No blogs matching the search text!");
        }
      }
    } catch (error) {
      setError("Error searching blogs.");
      console.error("Failed to load user actions:", error);
    }
  };

  const loadBlogs = () => {
    fetchMoreBlogs();
  };

  useEffect(() => {
    fetchBlogs(sortBy);
  }, [sortBy]);

  return (
    <div>
      <div>
        <SiteNavbar />
      </div>
      <div className="wrapper mb-5 mt-5">
        <section className="mb-2">
          <div className="py-4">
            <div className="mb-3 mb-lg-4 mt-5">
              <h4 className="text-grey">Blogs</h4>
            </div>
            <div className="d-flex justify-content-start">
              <Form
                className="d-flex align-items-center bg-white border-primary border-radius rounded p-1 me-3"
                style={{ maxWidth: 400 }}
                onSubmit={(e) => e.preventDefault()}
              >
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2 border-0"
                  aria-label="contact_email"
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <IoSearch size={28} className="text-primary me-1" />
              </Form>
              <div className="d-flex">
                <Form.Select
                  className="border-primary rounded bg-white focus-none"
                  onChange={(e) => {
                    setSortBy(e.target.value);
                    fetchBlogs(e.target.value);
                  }}
                  value={sortBy}
                >
                  <option value="LATEST">Latest</option>
                  <option value="OLDEST">Oldest</option>
                </Form.Select>
              </div>
            </div>
          </div>
        </section>
        {blogsData.length > 0 ? (
          <section>
            <div className="mb-4">
              <Row className="p-0" xs={1} md={2} lg={3}>
                {blogsData.map((blog, index) => (
                  <Col key={index} className="py-3">
                    <BlogCard
                      blog_id={blog.id}
                      title={blog.title}
                      date_published={blog.published_date}
                      img={blog.blog_image}
                      blog_slug={blog.slug}
                    />
                  </Col>
                ))}
              </Row>
            </div>
            {nextUrl && (
              <div className="d-flex justify-content-center">
                <Button
                  className="d-flex align-items-center bg-white border-primary focus-none px-3 py-3"
                  onClick={loadBlogs}
                >
                  <p className="text-uppercase text-primary mx-2">Explore more blogs</p>
                  <BiChevronsRight size={24} className="text-primary" />
                </Button>
              </div>
            )}
          </section>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center py-4"
            style={{ minHeight: 300 }}
          >
            {error ? <p>{error}</p> : <Spinner animation="border" />}
          </div>
        )}
        <section>
          <StayInTouch />
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Blogs;
