import { Earth_desktop, Earth_mobile } from "assets";
import { useEffect, useRef, useState, lazy } from "react";
import { Button, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { BiChevronsRight } from "react-icons/bi";
import { useSpringRef, useTransition, animated } from "@react-spring/web";
const BlogCardHome = lazy(() => import("components/Blogs/BlogCardHome"));
const Footer = lazy(() => import("components/Footer/SiteFooter"));
const StayInTouch = lazy(() => import("components/Home/StayInTouch"));
const SiteNavbar = lazy(() => import("components/Navbar/Navbar"));

const GetInTouchButtonHome = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  return (
    <Button
      variant="primary"
      className="d-flex justify-content-center align-items-center px-3 px-sm-4 py-sm-2 focus-none"
      style={{
        minWidth: 190,
        height: large ? 56 : medium ? 50 : 44,
        borderRadius: 12,
      }}
      // onClick={() => navigate("/get-in-touch")}
      onClick={() => handleNavigationClick("/get-in-touch")}
    >
      <p className="text-white">Get In Touch</p>
    </Button>
  );
};

const Home = () => {
  const coverSectionRef = useRef(null);
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [isRotated, setRotated] = useState(false);
  const [isFixed, setIsFixed] = useState(true);
  const [blogsData, setBlogsData] = useState([]);
  const title = "The Earth We Can't Afford to Ignore";
  const subtitle =
    "Join eoroe to Take Action Against Pollution and Climate Change";

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const fetchBlogs = async () => {
    try {
      const userActions = await import("actions/UserActions");
      const response = await userActions.fetchBlogs(`/blogs/?ordering=-published_date`);
      if (response) {
        setBlogsData(response.results);
      }
    } catch (error) {
      console.error("Failed to load user actions:", error);
    }
  };

  // const handleScroll = () => {
  //   const scrollThreshold = 100;
  //   const scrollY = window.scrollY || window.pageYOffset;
  //   // console.log(scrollY);

  //   if (!isRotated) {
  //     if (scrollY > scrollThreshold && scrollY < 200) {
  //       // console.log("Scrolled beyond");
  //       onClick();
  //       setRotated(true);
  //       const earthImgDesktop = document.getElementById("earthImgDesktop");
  //       earthImgDesktop.style.transform = "rotate(+180deg)";
  //       earthImgDesktop.style.transition = "transform 0.8s";
  //       earthImgDesktop.style.transitionTimingFunction = "ease-in-out";
  //       setTimeout(() => setIsFixed(false), 1000);
  //       window.removeEventListener("wheel", handleScroll);
  //       window.addEventListener("wheel", handleScrollReverse);
  //     }
  //   }
  // };

  const handleScrollReverse = (e) => {
    // console.log(scrollY)
    // console.log(e.deltaY, isRotated);
    // console.log(window);
    // console.log(e)

    // let options = {
    //   rootMargin: "-0px"
    // };

    let observer = new IntersectionObserver((entries) => {
      // alert();
      if (entries[0].intersectionRatio >= 0.9) {
        if (e.deltaY < 0) {
          onClick();
          setRotated(false);
          const earthImgDesktop = document.getElementById("earthImgDesktop");
          earthImgDesktop.style.transform = "rotate(0deg)";
          earthImgDesktop.style.transition = "transform 0.8s";
          earthImgDesktop.style.transitionTimingFunction = "ease-in-out";
          setTimeout(() => {
            setIsFixed(true);
          }, 1000);
          window.removeEventListener("wheel", handleScrollReverse);
          window.addEventListener("wheel", onWheel);
        }
      }
    });

    observer.observe(document.getElementById("cover_section"));
  };

  // useEffect(() => {
  // const scrollSection = coverSectionRef.current;
  //   const scrollSection = document.getElementById("cover_section");
  //   console.log(scrollSection);
  //   scrollSection?.addEventListener("wheel", () =>
  //     handleCustomScroll(scrollSection)
  //   );
  //   // return () => {
  //   //   scrollSection.removeEventListener("wheel", handleCustomScroll);
  //   // };
  // }, [window.scrollY]);

  const [index, set] = useState(0);
  const onClick = () => set((state) => (state + 1) % 2);
  const transRef = useSpringRef();
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: {
      opacity: 0,
      transform: isFixed ? "translate3d(0,75px,0)" : "translate3d(0, -75x, 0)",
    },
    enter: {
      opacity: 1,
      transform: isFixed ? "translate3d(0,0px,0)" : "translate3d(0, 0px, 0)",
    },
    leave: {
      opacity: 0,
      transform: isFixed ? "translate3d(0,-75px,0)" : "translate3d(0, 75px, 0)",
    },
    config: { duration: 700 },
  });

  useEffect(() => {
    transRef.start();
  }, [index, transRef]);

  const onWheel = (e) => {
    // console.log(scrollY);
    if (e.deltaY > 0) {
      // console.log(e)
      onClick();
      setRotated(true);
      const earthImgDesktop = document.getElementById("earthImgDesktop");
      earthImgDesktop.style.transform = "rotate(+180deg)";
      earthImgDesktop.style.transition = "transform 0.8s";
      earthImgDesktop.style.transitionTimingFunction = "ease-in-out";
      setTimeout(() => setIsFixed(false), 1000);
      // window.removeEventListener("scroll", handleScroll);
      // window.addEventListener("scroll", handleScrollReverse);
      window.removeEventListener("wheel", onWheel);
      window.addEventListener("wheel", handleScrollReverse);
      // return () => {
      //   window.removeEventListener("scroll", handleScroll);
      // };
    }
  };

  useEffect(() => {
    // window.addEventListener("wheel", (e) => {e.preventDefault(); e.stopPropagation(); return false;})
    window.addEventListener("wheel", onWheel);
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div
      className={`vh-100 w-100 scrollbar-none bg-whitish`}
      style={{ overflow: "scroll" }}
    >
      <div className="w-100">
        <SiteNavbar className="position-absolute top-0 w-100" />
      </div>
      <section
        ref={coverSectionRef}
        id="cover_section"
        className={`d-flex flex-column align-items-center vh-100 w-100 ${
          large ? (isRotated ? "bg-lightgreen" : "bg-white") : "bg-light"
        } ${large ? (isFixed ? "position-fixed" : "position-relative") : ""}`}
        style={{ paddingTop: large ? 200 : medium ? 200 : 160, zIndex: 10 }}
      >
        <div className="d-none d-lg-flex w-100 h-25">
          {transitions((style, i) => {
            if (i === 0) {
              return (
                <animated.div
                  className="d-flex flex-column justify-content-center align-items-center text-center px-2 px-md-3 position-absolute w-100"
                  style={{ ...style }}
                >
                  <h1 className="mb-4 h3">
                    The Earth We Can't Afford to Ignore
                  </h1>
                  <h4 className="mb-5 subtitle">
                    Join eoroe to Take Action Against Pollution and Climate
                    Change
                  </h4>
                </animated.div>
              );
            }
            return (
              <animated.div
                className="d-flex flex-column justify-content-center align-items-center text-center px-2 px-md-3 position-absolute w-100"
                style={{ ...style }}
              >
                <h2 className="mb-4 h3">
                  Green Earth: The Vision We Need to Achieve
                </h2>
                <h4 className="mb-5 subtitle">
                  Join eoroe to Learn How You Can Make a Difference Today
                </h4>
              </animated.div>
            );
          })}
        </div>
        <div className="d-lg-none d-flex justify-content-center">
          <div className="d-flex flex-column justify-content-center align-items-center text-center px-2 px-md-3 w-100">
            <h1 className="mb-4">Green Earth: The Vision We Need to Achieve</h1>
            <h4 className="mb-5">
              Join eoroe to Learn How You Can Make a Difference Today
            </h4>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5 mt-sm-0">
          <div id="getInTouchBtn">
            <GetInTouchButtonHome />
          </div>
        </div>
        {/* <div className="d-lg-none py-5 my-5 border border-dark">
            <img
              src={Earth_desktop}
              alt=""
              className="img-fluid position-absolute bottom-0"
              style={styles.earthImgMobile}
            />
          </div> */}
        {/* <div className="d-lg-none p-4 py-lg-5 my-lg-5 border border-dark position-relative bottom-0 left-50"> */}
        <div className="d-flex d-lg-none flex-grow-1 position-relative w-100 justify-content-center">
          <img
            src={Earth_mobile}
            alt="earth"
            className="d-lg-none img-fluid position-absolute bottom-0 "
            // className="img-fluid position-absolute bottom-0"
            // className="img-fluid"
            // style={styles.earthImgMobile}
            style={{
              width: medium ? 450 : 300,
              height: medium ? 450 : 300,
              marginBottom: medium ? -225 : -150,
            }}
          />
        </div>
        {/* </div> */}
        <div
          className="d-none d-lg-flex flex-grow-1 overflow-hidden position-relative w-100"
          style={{ height: 350 }}
        >
          {/* <amp-img */}
          <Image
            id="earthImgDesktop"
            src={Earth_desktop}
            alt="earth"
            className="img-fluid position-absolute bottom-0"
            style={styles.earthImgDesktop}
          />
        </div>
      </section>
      <section
        className={`wrapper pt-5 ${large ? "bg-whitish" : "bg-lightgreen"}`}
      >
        <div
          className={`d-flex flex-column justify-content-center align-items-center d-lg-none text-center px-2 px-md-3 mb-5 mb-md-3 ${
            large ? "bg-whitish" : "bg-lightgreen"
          }`}
          style={{
            marginTop: medium ? 350 : 300,
            paddingBottom: medium ? 180 : 160,
          }}
        >
          <h2 className="mb-4">
            {large ? title : "The Earth We Can't Afford to Ignore"}
          </h2>
          <h4 className="mb-5">
            {large
              ? subtitle
              : "Join eoroe to Take Action Against Pollution and Climate Change"}
          </h4>
          <div id="getInTouchBtn">
            <GetInTouchButtonHome />
          </div>
        </div>
      </section>
      <section className="wrapper">
        <div className="bg-whitish" style={{ paddingTop: large ? 50 : 0 }}>
          {/* <div> */}
          <div className="py-2 py-md-3 mb-2 mb-lg-4">
            <h3 className="subtitle">Blogs</h3>
          </div>
          <div>
            {blogsData?.length > 0 ? (
              blogsData?.map((blog, index) => {
                return (
                  index < 3 && (
                    <BlogCardHome
                      key={index}
                      blog_id={blog?.id}
                      title={blog.title}
                      date_published={blog.published_date}
                      content={blog.content}
                      img={blog.blog_image}
                      blog_slug={blog?.slug}
                    />
                  )
                );
              })
            ) : (
              <div className="py-4">
                <p className="text-primary text-center">Loading...</p>
              </div>
            )}
          </div>
        </div>
        {blogsData.length > 0 && (
          <div className="d-flex justify-content-center">
            <Button
              className="d-flex align-items-center py-2 mb-5 focus-none px-3 py-3 explore-blogs-btn"
              // onClick={() => navigate("/blogs")}
              onClick={() => handleNavigationClick("/blogs")}
              variant="outline-primary"
              style={{ borderRadius: "8px" }}
            >
              <p className="text-uppercase mx-2 text-primary">
                Explore more blogs
              </p>
              <BiChevronsRight size={24} className="text-primary" />
            </Button>
          </div>
        )}
      </section>
      <section className={``}>
        <StayInTouch />
      </section>
      <div className={``}>
        <Footer />
      </div>
    </div>
  );
};

const styles = {
  earthImgMobile: {},
  earthImgDesktop: {
    width: 700,
    height: 700,
    left: "50%",
    marginLeft: -350,
    marginBottom: -350,
    transition: "transform 0.3s",
  },
};
export default Home;
