import { Earth, Eoroe_logo } from "assets";
import { Container, Navbar } from "react-bootstrap";

const Main = () => {
  return (
    <>
      <Container fluid className="p-0 m-0 h-100">
        <div className="d-flex h-100">
          <div
            style={styles.leftContainer}
            className="d-inline-block position-relative"
          >
            <Navbar>
              <Navbar.Brand
                href=""
                style={styles.navbrand_text}
                className="mx-5 my-2"
              >
                <img
                  src={Eoroe_logo}
                  alt="eoroe"
                  className="d-inline-block align-top img-fluid ms-5 me-2"
                />
                eoroe
              </Navbar.Brand>
            </Navbar>
            <div style={styles.titleBox}>
              <h1 style={styles.title} className="mb-5">
                For <br /> Sake of <br /> Our <br /> Earth
              </h1>
              <p style={styles.text}>
                Hang in there, beautiful days are coming.....
              </p>
            </div>
            {/* <div className="position-fixed d-block border border-dark"> */}
            <img
              src={Earth}
              alt="earth"
              className="img-fluid position-absolute end-0"
              style={styles.earthImg}
            />
            {/* </div> */}
          </div>
          <div style={styles.rightContainer} className="d-inline-block">
            <p style={styles.co2Text} className="float-end">
              CO2
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};
const styles = {
  leftContainer: {
    background: "#E6FFF3",
    width: "67%",
    height: "100%",
  },
  rightContainer: {
    background: "#E3E5E3",
    width: "33%",
    height: "100%",
  },
  navbrand_text: {
    fontWeight: 500,
    color: "#161616",
  },
  titleBox: {
    margin: "7rem 2rem 0 10rem ",
  },
  title: {
    fontSize: "5rem",
    fontWeight: 600,
    lineHeight: "112%",
    color: "#00A85A",
    textShadow: "4px 4px 4px rgba(0, 0, 0, 0.12)",
  },
  text: {
    fontWeight: 500,
    lineHeight: "112%",
    color: "#5C6553",
  },
  co2Text: {
    margin: "5rem 0 0 0",
    fontSize: "6rem",
    fontWeight: "700",
    lineHeight: "112%",
    color: "#2B2B2B",
    opacity: "0.03",
    transform: "rotate(90deg)",
  },
  earthImg: {
    width: "41%",
    height: "auto",
    top: "20%",
    marginRight: "-21%",
  },
};

export default Main;
