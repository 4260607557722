import "./App.css";
import "./App.scss";
import { Container } from "react-bootstrap";
import Routers from "Routers";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      <Container fluid style={styles.parentContainer}>
        <Routers />
      </Container>
    </>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
    padding: "0",
  },
};

export default App;
