import { useEffect, useRef, useState, lazy } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Get_in_touch_desktop, Get_in_touch_mobile } from "assets";
import { ImMail4 } from "react-icons/im";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const Footer = lazy(() => import("components/Footer/SiteFooter"));
const SiteNavbar = lazy(() => import("components/Navbar/Navbar"));

const GetInTouch = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const [phoneCodes, setPhoneCodes] = useState([]);
  const [phoneCodeVal, setPhoneCodeVal] = useState("+91");

  // const fetchCountries = async () => {
  //   const response = await userActions.fetchCountries();
  //   if (response) setPhoneCodes(response);
  // };

  const fetchPhoneCodes = async () => {
    const userActions = await import("actions/UserActions");
    const response = await userActions.fetchPhoneCodes();
    if (response) {
      // console.log(response);
      setPhoneCodes(response);
    }
  };

  const handleFormChange = (e) => {
    const formData = new FormData(formRef.current);
    const temp = Array.from(formData.values()).every(value => value.trim() !== '');
    setEnabled(temp);
  }

  const handleFormSubmission = async (e) => {
    setLoading(true);
    const userActions = await import("actions/UserActions");
    // console.log("ContactUs form");
    e.preventDefault();
    if (phoneCodeVal) {
      const formData = new FormData(formRef.current);
      formData.append("phone_code", phoneCodeVal);
      // for (const [name, value] of formData.entries()) {
      //   console.log(`${name}: ${value}`);
      // }
      const response = await userActions.addContact(formData);
      if (response) {
        formRef.current.reset();
        // console.log(response);
        userActions.notify("Thank you for contacting us. Our team will reach to you soon.", "success")
        navigate("/get-in-touch");
      } else {
        userActions.notify("Error while submitting form.", "error");
      }
      setLoading(false);
    } else {
      userActions.notify("Please select phone code !");
    }
  };

  useEffect(() => {
    // console.log(phoneCodeVal);
  }, [phoneCodeVal]);

  useEffect(() => {
    // fetchCountries();
    fetchPhoneCodes();
  }, []);

  return (
    <div>
      <div>
        <SiteNavbar />
      </div>
      <section className="wrapper p-3 p-sm-3 p-lg-5 mt-5">
        <div
          className="bg-lightgreen p-2 p-sm-3 p-lg-4 mt-5"
          style={{ borderRadius: large ? 32 : medium ? 24 : 16 }}
        >
          <Row className="p-2 p-lg-4" xs={1} lg={2}>
            <Col>
              <div className="d-flex flex-column align-items-center align-items-lg-start text-center text-lg-start mb-4 h-100">
                <h1 className="mb-4">
                  Get in touch <br />
                  with us
                </h1>
                <div className="d-flex align-items-center mb-4">
                  <ImMail4 size={28} className="me-3" />
                  <a href="mailto:talk2us@eoroe.com">
                    <p>talk2us@eoroe.com</p>
                  </a>
                </div>
                <div className="d-none d-lg-flex align-items-center h-100">
                  <img
                    src={Get_in_touch_desktop}
                    alt="get in touch"
                    className="img-fluid"
                  />
                </div>
                <div className="d-lg-none mb-4">
                  <img src={Get_in_touch_mobile} alt="get in touch" className="img-fluid" />
                </div>
              </div>
            </Col>
            <Col
              className="bg-white p-3 p-lg-4"
              style={{ borderRadius: large ? 24 : medium ? 20 : 16 }}
            >
              <Form ref={formRef} onChange={handleFormChange} onSubmit={(e) => handleFormSubmission(e)}>
                <Form.Group className="mb-3" controlId="contact.name">
                  <Form.Label>
                    <p>Full Name</p>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    name="name"
                    required
                    className="py-2"
                    style={{ borderRadius: 8, height: large ? 52 : 48 }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="contact.email">
                  <Form.Label>
                    <p>Email address</p>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="john@testmail.com"
                    name="email"
                    required
                    className="py-2"
                    style={{ borderRadius: 8, height: large ? 52 : 48 }}
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="contact.mobile">
                  <Form.Label>
                    <p>Contact Number</p>
                  </Form.Label>
                  <Form.Control
                    type="mobile"
                    placeholder="+91 0000000000"
                    name="mobile"
                    required
                    className="py-2"
                    style={{ borderRadius: 8, height: large ? 52 : 48 }}
                  />
                </Form.Group> */}
                <div>
                  <Form.Label>
                    <p>Contact number</p>
                  </Form.Label>
                  <div className="d-sm-flex p-0">
                    {/* <Col xs={5} sm={3} lg={3} className="p-0 pe-2"> */}
                    <Form.Group className="mb-3 me-sm-2 px-0">
                      <Form.Select
                        aria-label="phoneCode"
                        className="py-2 px-sm-3"
                        name="phonecode"
                        style={{
                          borderRadius: 8,
                          height: large ? 52 : 48,
                          minWidth: 130,
                        }}
                        onChange={(e) => {
                          setPhoneCodeVal(e.target.value);
                        }}
                        value={phoneCodeVal}
                      >
                        <option className="focus-none">Phonecode</option>
                        {phoneCodes.sort((a, b) => a.phonecode - b.phonecode).map((item, index) => {
                          return (
                            <option
                              key={item.id}
                              value={item.phonecode}
                              className="focus-none py-2"
                            >
                              {item.flag + " " + item.phonecode}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    {/* </Col>
                    <Col xs={7} sm={9} lg={9} className="p-0"> */}
                    <Form.Group className="mb-3 w-100">
                      <Form.Control
                        type="contact"
                        placeholder="Enter mobile"
                        name="mobile"
                        pattern="[0-9]{10}"
                        className="py-2"
                        style={{ borderRadius: 8, height: large ? 52 : 48 }}
                        required
                        onInvalid={(e) => {
                          return e.target.setCustomValidity(
                            "Please enter valid mobile number."
                          );
                        }}
                        onChange={(e) => {
                          // setMobile(e.target.value);
                          return e.target.setCustomValidity("");
                        }}
                      />
                    </Form.Group>
                    {/* </Col> */}
                  </div>
                </div>
                {/* <Form.Group className="mb-3" controlId="contact.name">
                  <Form.Label><p>Full Name</p></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="XYZ Pvt Ltd"
                    name="company_name"
                    className="py-2"
                    style={{ borderRadius: 8, height:large?52:48 }}
                  />
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="contact.message">
                  <Form.Label>
                    <p>Message</p>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="I'm interested in learning more about..."
                    name="message"
                    required
                    className="py-2"
                    style={{ borderRadius: 8, height: large ? 125 : 106 }}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant={`${enabled ? "primary" : "light"}`}
                  className="py-3 w-100 focus-none text-bold text-uppercase d-flex align-items-center justify-content-center"
                  style={{ borderRadius: 12 }}
                >
                  <div>{!loading ? "SEND MESSAGE" : "SENDING"}</div>
                  {loading && (
                    <Spinner
                      size="sm"
                      animation="border"
                      role="status"
                      className="ms-2"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default GetInTouch;
